<template>
  <div class="flex flex-col md:flex-row gap-4">
    <UISelectFixed v-if="!verbergType" type="STATISTIEKEN_DMY" v-model="typeModel" />

    <!-- @TODO: Wekelijkse stats -->
    <!-- <UISelectFixed
      v-if="!verbergType"
      :type="toonWeek ? 'STATISTIEKEN_DWMY' : 'STATISTIEKEN_DMY'"
      v-model="typeModel"
    /> -->

    <UISelectMinMax
      v-if="!verbergDay && typeModel === 'DAGELIJKS'"
      min="1"
      :max="maxDay"
      label="Dag"
      v-model="dayModel"
      :prefix-options="typeModel === 'DAGELIJKS' && !verbergAlleDays ? [{ value: null, label: 'Alle dagen' }] : []"
    />

    <UISelectFixed
      v-if="!verbergMonth && typeModel !== 'JAARLIJKS'"
      type="MAAND"
      v-model="monthModel"
      :prefix-options="typeModel === 'MAANDELIJKS' && !verbergAlleMonths ? [{ value: null, label: 'Alle maanden' }] : []"
    />

    <UISelectMinMax
      v-if="!verbergYear"
      :min="minYear"
      :max="maxYear + 3"
      label="Jaar"
      v-model="yearModel"
      :prefix-options="typeModel === 'JAARLIJKS' && !verbergAlleYears ? [{ value: null, label: 'Alle jaren' }] : []"
    />

    <UISelectChauffeur v-if="toonChauffeurs" class="max-w-xs" label nullable v-model="userIdModel" />
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useVModel } from '@vueuse/core'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'

const d = new Date()

const maxYear = d.getFullYear()
const maxDay = computed(() => new Date(yearModel.value, monthModel.value, 0).getDate())

const props = defineProps({
  minYear: {
    type: [Number, String],
    default: 2018,
  },
  type: {
    type: [String],
    default: 'DAGELIJKS',
  },
  day: {
    type: [Number, String],
    default() { return null },
  },
  month: {
    type: [Number, String],
    default() { return null },
  },
  year: {
    type: [Number, String],
    default() { return null },
  },
  user_id: {
    type: [Number, String],
    default() { return null },
  },
  verbergType: Boolean,
  verbergDay: Boolean,
  verbergMonth: Boolean,
  verbergYear: Boolean,
  verbergAlleDays: Boolean,
  verbergAlleWeken: Boolean,
  verbergAlleMonths: Boolean,
  verbergAlleYears: Boolean,
  toonChauffeurs: Boolean,
  toonWeek: Boolean,
})

const emit = defineEmits(['update:type', 'update:day', 'update:month', 'update:year', 'update:user_id'])

const typeModel = useVModel(props, 'type', emit, { passive: true })
const dayModel = useVModel(props, 'day', emit, { passive: true })
const weekModel = useVModel(props, 'week', emit, { passive: true })
const monthModel = useVModel(props, 'month', emit, { passive: true })
const yearModel = useVModel(props, 'year', emit, { passive: true })
const userIdModel = useVModel(props, 'user_id', emit, { passive: true })

watch(() => typeModel.value, (type, oldtype) => {
  if (type === oldtype) return
  handleTypeChange(type)
}, { deep: true })

const handleTypeChange = (type) => {
  const d = new Date()
  if (type === 'DAGELIJKS') {
    dayModel.value = props.day || null
    monthModel.value = props.month || d.getMonth() + 1
    yearModel.value = props.year || d.getFullYear()
  } else if (type === 'WEEKELIJKS') {
    weekModel.value = props.week || null
    monthModel.value = props.month || d.getMonth() + 1
    yearModel.value = props.year || d.getFullYear()
  } else if (type === 'MAANDELIJKS') {
    monthModel.value = props.month || d.getMonth() + 1
    yearModel.value = props.year || d.getFullYear()
  } else if (type === 'JAARLIJKS') {
    yearModel.value = props.year || d.getFullYear()
  }
  if (type !== 'DAGELIJKS') {
    dayModel.value = null
  }
  if (type === 'JAARLIJKS') {
    monthModel.value = null
  }
}

onMounted(() => {
  handleTypeChange(typeModel.value)
})
</script>
