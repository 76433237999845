<template>
  <UISelectFixed v-bind="$attrs" v-model="innerValue" :options="options" />
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import UISelectFixed from '@/components/UI/Select/Fixed'
import useMinMaxValues, { PROPS as PropsMinMax } from '@/hooks/useMinMaxValues'

const props = defineProps({
  prefixOptions: Array,
  suffixOptions: Array,
  ...PropsMinMax,
})

const emit = defineEmits(['update:modelValue'])
const { minInner, maxInner, innerValue } = useMinMaxValues(props, emit)

const options = computed(() => {
  let result = []
  for (var n = Number(minInner.value); n <= Number(maxInner.value); n += 1) {
    result.push({ value: n, label: n })
  }
  result = [].concat(props.prefixOptions || [], result, props.suffixOptions || [])
  return result
})
</script>
